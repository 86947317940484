import React from "react";
import { Row, Col, Carousel, Card } from "antd";
import {
  Layout,
  SEO,
  Colors,
  ContainerContent,
  InternalLink,
  InternalLinkTypes,
  DisplayRange,
  TitleSection,
  ContainerCta,
  ButtonPrimary,
  DisplayReviewList,
} from "../components";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
const buttonStyles = {
  background: Colors.LightGreen,
  color: Colors.DarkGray,
  borderRadius: "4px",
  fontSize: "3rem",
  padding: "2rem",
  fontWeight: 600,
  textTransform: "uppercase",
  lineHeight: "3.5rem",
  // "-webkit-text-stroke-width": "1px",
  // "-webkit-text-stroke-color": Colors.DarkGray,
  // "-webkit-text-fill-color": "transparent",
};
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allReviewsJson {
        nodes {
          client
          title
          platform
          review
          date
          slug
          homeDesignSlug
          imagePath {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      allRangesJson {
        nodes {
          id
          name
          description
          slug
          range
          imagePath {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      BannerImage: file(relativePath: { eq: "banner/gg_banner.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage2: file(
        relativePath: { eq: "displays/lilac-olivine-donnybrook/10.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage3: file(
        relativePath: { eq: "displays/lilac-olivine-donnybrook/11.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage4: file(
        relativePath: { eq: "displays/lilac-olivine-donnybrook/27.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage5: file(
        relativePath: { eq: "displays/lilac-olivine-donnybrook/16.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage6: file(
        relativePath: { eq: "displays/lilac-olivine-donnybrook/2.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage7: file(
        relativePath: { eq: "displays/lilac-olivine-donnybrook/31.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage8: file(
        relativePath: { eq: "displays/acacia-redstone-sunbury/2.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage9: file(
        relativePath: { eq: "displays/acacia-redstone-sunbury/14.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage10: file(
        relativePath: { eq: "displays/aconite-redstone-sunbury/17.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage11: file(
        relativePath: { eq: "displays/aconite-redstone-sunbury/33.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage14: file(
        relativePath: { eq: "displays/heather-katalia-donnybrook/2.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage12: file(
        relativePath: { eq: "displays/heather-katalia-donnybrook/7.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerImage13: file(
        relativePath: { eq: "displays/heather-katalia-donnybrook/16.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const reviews = data.allReviewsJson.nodes.slice(0, 3);
  return (
    <Layout>
      <SEO
        title="Grandeur Homes | Leading custom home builder in Victoria"
        description={
          "Building your grand vision, with perfection! Delivering well crafted custom luxury homes in Victoria."
        }
      />

      {/*<div*/}
      {/*  className="video"*/}
      {/*  style={{*/}
      {/*    position: "relative",*/}
      {/*    paddingBottom: "56.25%" ,*/}
      {/*    paddingTop: 25,*/}
      {/*    height: 0,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <iframe*/}
      {/*    title={"grandeur-homes-lilac-33"}*/}
      {/*    // width="853"*/}
      {/*    // height="480"*/}
      {/*    src={`https://www.realtour3d.com.au/3d-model/${"grandeur-homes-lilac-33"}/fullscreen/?embedded`}*/}
      {/*    frameBorder="0"*/}
      {/*    allow="vr"*/}
      {/*    allowFullScreen="allowfullscreen"*/}
      {/*    style={{*/}
      {/*      margin: 0,*/}
      {/*      position: "absolute",*/}
      {/*      top: 0,*/}
      {/*      left: 0,*/}
      {/*      width: "100%",*/}
      {/*      height: "100%",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}
      <Carousel
        autoplay={true}
        dotPosition={"top"}
        dots={false}
        effect={"fade"}
      >
         <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage8"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage9"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage10"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage11"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage12"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage13"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage14"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage2"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage3"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage4"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage5"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage6"].childImageSharp.fluid}
          />
        </div>{" "}
        <div>
          <Img
            style={{ maxHeight: "600px" }}
            fluid={data["BannerImage7"].childImageSharp.fluid}
          />
        </div>
      </Carousel>
      <div
        style={{
          padding: "0rem 1rem 1rem 1rem",
          textAlign: "center",
          marginTop: "-4rem",
          background: Colors.DarkGray,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col md={8} xs={24}>
            <InternalLink url={InternalLinkTypes.Displays}>
              <div style={buttonStyles}>
                Display
                <br />
                Homes
              </div>
            </InternalLink>
          </Col>
          <Col md={8} xs={24}>
            <InternalLink url={InternalLinkTypes.HomeDesigns}>
              <div style={buttonStyles}>
                Home
                <br />
                Designs
              </div>
            </InternalLink>
          </Col>
          <Col md={8} xs={24}>
            <InternalLink url={InternalLinkTypes.GetStarted}>
              <div style={buttonStyles}>
                Get
                <br />
                Started
              </div>
            </InternalLink>
          </Col>
        </Row>
      </div>
      <section>
        <ContainerContent
          style={{
            textAlign: "center",
            width: "100%",
            // background: "rgba(255, 255, 255, 0.85)",
            backgroundColor: Colors.DarkGray,
            // background:
            //   "50% 50% / 150% 150% no-repeat  radial-gradient(ellipse at bottom, #2ba652, #8dc149, #bdd043)",
          }}
        >
          {/*<h2*/}
          {/*  style={{*/}
          {/*    fontWeight: 600,*/}
          {/*    color: Colors.White,*/}
          {/*    fontSize: "2rem",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Building your Grand Vision, with perfection!*/}
          {/*</h2>*/}
          {/*<h4*/}
          {/*  style={{*/}
          {/*    color: Colors.White,*/}
          {/*    fontWeight: "400",*/}
          {/*    fontSize: "1.5rem",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Delivering well crafted custom luxury homes in Victoria.*/}
          {/*</h4>*/}
          {/*<h1*/}
          {/*  style={{*/}
          {/*    color: Colors.LightGreen,*/}
          {/*    fontWeight: 400,*/}
          {/*    fontFamily: "Allura",*/}
          {/*    fontSize: "3.5rem",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Custom Home Builder*/}
          {/*</h1>*/}
          <h2
            style={{
              textTransform: "uppercase",
              color: Colors.White,
              fontSize: "1.5rem",
              //  fontWeight: 800,
              // "-webkit-text-stroke-width": "1px",
              // "-webkit-text-stroke-color": Colors.LightGreen,
              // "-webkit-text-fill-color": "transparent",
            }}
          >
            Leading custom home builder in Victoria
          </h2>{" "}
          <Img
            style={{
              margin: "2rem auto",
              maxWidth: "800px",
              //left: "28px",
            }}
            fluid={data["BannerImage"].childImageSharp.fluid}
          />
          <InternalLink url={InternalLinkTypes.About}>
            Learn more about us and our brands
          </InternalLink>
        </ContainerContent>
      </section>
      {/*<ContainerContent*/}
      {/*  style={{*/}
      {/*    background: Colors.DarkGray,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  /!*<h2 style={{ textAlign: "center", color: Colors.White }}>OUR BRANDS</h2>*!/*/}
      {/*  <Img*/}
      {/*    style={{ margin: "auto", maxWidth: "800px" }}*/}
      {/*    fluid={data["BannerImage"].childImageSharp.fluid}*/}
      {/*  />*/}
      {/*</ContainerContent>*/}

      <ContainerContent style={{ background: Colors.LightGray }}>
        <TitleSection style={{ textAlign: "center" }} color={Colors.DarkGreen}>
          Explore our ranges
        </TitleSection>
        {/*<div>*/}
        {/*  {data.allRangesJson.nodes.map(item => (*/}
        {/*    <Row key={item.id} style={{ margin: "32px 0px 32px -32px" }}>*/}
        {/*      <Col md={16} xs={24}>*/}
        {/*        <Img fluid={item.imagePath.childImageSharp.fluid} />*/}
        {/*      </Col>*/}
        {/*      <Col md={8} xs={24}>*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            backgroundColor: Colors.DarkGray,*/}
        {/*            minHeight: "400px",*/}
        {/*            padding: "2.5rem 2rem",*/}
        {/*            borderRadius: "0 4px 4px 0",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <InternalLink url={item.slug}>*/}
        {/*            <DisplayRange*/}
        {/*              range={item.range}*/}
        {/*              style={{*/}
        {/*                fontSize: "3.5rem",*/}
        {/*                color: RANGES_COLORS[item.range],*/}
        {/*                backgroundColor: "transparent",*/}
        {/*                lineHeight: "3rem",*/}
        {/*                padding: "1rem 0",*/}
        {/*              }}*/}
        {/*            />*/}
        {/*            <h2*/}
        {/*              style={{*/}
        {/*                fontWeight: 400,*/}
        {/*                fontSize: "1.5rem",*/}
        {/*                color: Colors.White,*/}
        {/*                margin: "1.5rem 0",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              {item.description}*/}
        {/*            </h2>*/}

        {/*            <ButtonSecondary>See {item.name}</ButtonSecondary>*/}
        {/*          </InternalLink>*/}
        {/*        </div>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  ))}*/}
        {/*</div>*/}
        <Row gutter={[16, 16]}>
          {data.allRangesJson.nodes.map(item => (
            <Col md={8} key={item.id}>
              <InternalLink url={item.slug}>
                <Card
                  cover={<Img fluid={item.imagePath.childImageSharp.fluid} />}
                  hoverable={true}
                >
                  <Card.Meta
                    title={<DisplayRange range={item.range} />}
                    description={item.description}
                  />
                  <br />
                  <ButtonPrimary>See {item.name}</ButtonPrimary>
                </Card>
              </InternalLink>
            </Col>
          ))}
        </Row>
      </ContainerContent>

      {/*<section>*/}
      {/*  <Row style={{ textAlign: "center" }}>*/}
      {/*    <Col md={12} xs={24}>*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          padding: "2rem",*/}
      {/*          backgroundColor: "rgba(19, 21, 19, 0.2)",*/}
      {/*          position: "relative",*/}
      {/*          zIndex: 3,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <div*/}
      {/*          style={{*/}
      {/*            minHeight: "500px",*/}
      {/*            backgroundColor: "rgba(255, 255, 255, 0.85)",*/}
      {/*            padding: "4rem 3.5rem",*/}
      {/*            maxWidth: "400px",*/}
      {/*            margin: "auto",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <h1*/}
      {/*            style={{*/}
      {/*              color: Colors.DarkGreen,*/}
      {/*              fontWeight: "200",*/}
      {/*              display: "inline-block",*/}
      {/*              paddingBottom: "6px",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Custom Homes*/}
      {/*          </h1>*/}
      {/*          <h2*/}
      {/*            style={{*/}
      {/*              color: Colors.Gray,*/}
      {/*              fontWeight: "600",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Single Storey*/}
      {/*          </h2>*/}
      {/*          <h3 style={{ fontWeight: 400 }}>*/}
      {/*            Living life in your very own Dream Home starts now*/}
      {/*          </h3>*/}
      {/*          <p*/}
      {/*            style={{*/}
      {/*              // fontSize: "1.1rem",*/}
      {/*              margin: "4.5rem auto",*/}
      {/*              maxWidth: "400px",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            You can have the perfect mix of convenience and comfort custom*/}
      {/*            built for you and your taste. Experience an exceptional home*/}
      {/*            with the experienced team behind you all the way. There’s*/}
      {/*            nothing greater than a Grandeur Home.*/}
      {/*          </p>*/}
      {/*          <br />*/}
      {/*          <ButtonSecondary style={{ margin: "4px" }}>*/}
      {/*            <InternalLink url={InternalLinkTypes.HomeDesignSingleStorey}>*/}
      {/*              Browse Designs*/}
      {/*            </InternalLink>*/}
      {/*          </ButtonSecondary>*/}
      {/*          <ButtonPrimary style={{ margin: "4px" }}>*/}
      {/*            <InternalLink url={InternalLinkTypes.GetStarted}>*/}
      {/*              Get Started*/}
      {/*            </InternalLink>*/}
      {/*          </ButtonPrimary>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <SingleStoreyImage*/}
      {/*        style={{*/}
      {/*          position: "absolute",*/}
      {/*          top: 0,*/}
      {/*          width: "100%",*/}
      {/*          height: "100%",*/}
      {/*          zIndex: 2,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*    <Col md={12} xs={24}>*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          padding: "2rem ",*/}
      {/*          backgroundColor: "rgba(19, 21, 19, 0.2)",*/}
      {/*          position: "relative",*/}
      {/*          zIndex: 3,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <div*/}
      {/*          style={{*/}
      {/*            minHeight: "500px",*/}
      {/*            backgroundColor: "rgba(255, 255, 255, 0.85)",*/}
      {/*            padding: "4rem 3.5rem",*/}
      {/*            maxWidth: "400px",*/}
      {/*            margin: "auto",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <h1*/}
      {/*            style={{*/}
      {/*              color: Colors.DarkGreen,*/}
      {/*              fontWeight: "200",*/}
      {/*              // borderBottom: "3px solid #fff",*/}
      {/*              display: "inline-block",*/}
      {/*              paddingBottom: "6px",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Custom Homes*/}
      {/*          </h1>*/}
      {/*          <h2*/}
      {/*            style={{*/}
      {/*              color: Colors.Gray,*/}
      {/*              fontWeight: "600",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Double Storey*/}
      {/*          </h2>*/}
      {/*          <h3 style={{ fontWeight: 400 }}>*/}
      {/*            Luxury on a Whole New Level <br />*/}
      {/*            <br />*/}
      {/*          </h3>*/}
      {/*          <p*/}
      {/*            style={{*/}
      {/*              // fontSize: "1.1rem",*/}
      {/*              margin: "4.5rem auto",*/}
      {/*              maxWidth: "400px",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Imagine living in a home that’s a perfect fit for your*/}
      {/*            lifestyle. There’s no need to imagine it any longer, it’s all*/}
      {/*            right here waiting for you. From the tailor-made design and*/}
      {/*            plans through to the finished product, we make your new home*/}
      {/*            happen.*/}
      {/*          </p>*/}
      {/*          <br />*/}
      {/*          <ButtonSecondary style={{ margin: "4px" }}>*/}
      {/*            <InternalLink url={InternalLinkTypes.HomeDesignDoubleStorey}>*/}
      {/*              Browse Designs*/}
      {/*            </InternalLink>*/}
      {/*          </ButtonSecondary>*/}
      {/*          <ButtonPrimary style={{ margin: "4px" }}>*/}
      {/*            <InternalLink url={InternalLinkTypes.GetStarted}>*/}
      {/*              Get Started*/}
      {/*            </InternalLink>*/}
      {/*          </ButtonPrimary>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <DoubleStoreyImage*/}
      {/*        style={{*/}
      {/*          position: "absolute",*/}
      {/*          top: 0,*/}
      {/*          width: "100%",*/}
      {/*          height: "100%",*/}
      {/*          zIndex: 2,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</section>*/}
      {/*<section>*/}
      {/*  <ContainerContent*/}
      {/*    style={{*/}
      {/*      backgroundColor: Colors.LightGray,*/}
      {/*      textAlign: "center",*/}
      {/*      // color: "white",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Row gutter={8}>*/}
      {/*      <Col md={12}>*/}
      {/*        <div style={{ marginTop: "16px" }}>*/}
      {/*          <h1 style={{ fontWeight: "200", color: Colors.DarkGreen }}>*/}
      {/*            Multi Unit Developments*/}
      {/*          </h1>*/}
      {/*          <h2 style={{ fontWeight: "100" }}>*/}
      {/*            Single & Double Storey Townhouses*/}
      {/*          </h2>*/}
      {/*          <p>To learn more, make an appointment with our sales team</p>*/}
      {/*          <ButtonSecondary style={{ margin: "4px" }}>*/}
      {/*            <InternalLink url={InternalLinkTypes.Contact}>*/}
      {/*              Contact Us*/}
      {/*            </InternalLink>*/}
      {/*          </ButtonSecondary>*/}
      {/*        </div>*/}
      {/*      </Col>*/}
      {/*      <Col md={12}>*/}
      {/*        <div style={{ marginTop: "16px" }}>*/}
      {/*          <h1 style={{ fontWeight: "200", color: Colors.DarkGreen }}>*/}
      {/*            House & Land Packages*/}
      {/*          </h1>*/}
      {/*          <h2 style={{ fontWeight: "100" }}>*/}
      {/*            Available in leading estates*/}
      {/*          </h2>*/}
      {/*          <p>To learn more, make an appointment with our sales team</p>*/}
      {/*          <ButtonSecondary style={{ margin: "4px" }}>*/}
      {/*            <InternalLink url={InternalLinkTypes.Contact}>*/}
      {/*              Contact Us*/}
      {/*            </InternalLink>*/}
      {/*          </ButtonSecondary>*/}
      {/*        </div>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </ContainerContent>*/}
      {/*</section>*/}
      {/*<section>*/}
      {/*  <ContainerContent style={{ textAlign: "center" }}>*/}
      {/*    <h1*/}
      {/*      style={{*/}
      {/*        color: Colors.DarkGreen,*/}
      {/*        fontWeight: "200",*/}
      {/*        display: "inline-block",*/}
      {/*        paddingBottom: "6px",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Custom Floor Plans*/}
      {/*    </h1>*/}
      {/*    <h3 style={{ fontWeight: "400" }}>*/}
      {/*      <b>Bring your own floor plan</b>, work with our architects or pick*/}
      {/*      from our range of floor plans*/}
      {/*    </h3>*/}
      {/*    <div style={{ marginBottom: "16px" }}>/!*<FloorPlan4 />*!/</div>*/}
      {/*    <ButtonSecondary style={{ margin: "4px" }}>*/}
      {/*      <InternalLink url={InternalLinkTypes.FloorPlans}>*/}
      {/*        Browse Floor Plans*/}
      {/*      </InternalLink>*/}
      {/*    </ButtonSecondary>*/}
      {/*    <ButtonPrimary style={{ margin: "4px" }}>*/}
      {/*      <InternalLink url={InternalLinkTypes.GetStarted}>*/}
      {/*        Customise Your Own*/}
      {/*      </InternalLink>*/}
      {/*    </ButtonPrimary>*/}
      {/*  </ContainerContent>*/}
      {/*</section>*/}
      {/*<section>*/}
      {/*  <ContainerContent*/}
      {/*    style={{ backgroundColor: Colors.LightGray, textAlign: "center" }}*/}
      {/*  >*/}
      {/*    <ContentLanguage />*/}
      {/*    <ButtonSecondary>*/}
      {/*      <InternalLink url={InternalLinkTypes.Contact}>*/}
      {/*        Contact Team*/}
      {/*      </InternalLink>*/}
      {/*    </ButtonSecondary>*/}
      {/*  </ContainerContent>*/}
      {/*</section>*/}

      {/*<section>*/}
      {/*  <ContainerContent*/}
      {/*    style={{*/}
      {/*      backgroundColor: "white",*/}
      {/*      textAlign: "center",*/}
      {/*      paddingTop: "0",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <SliderCustomHomes />*/}
      {/*    <div style={{ marginTop: "32px" }}>*/}
      {/*      <ButtonSecondary style={{ margin: "4px", minWidth: "250px" }}>*/}
      {/*        <InternalLink url={InternalLinkTypes.VideosGallery}>*/}
      {/*          Browse Videos Gallery*/}
      {/*        </InternalLink>*/}
      {/*      </ButtonSecondary>*/}
      {/*      <ButtonPrimary style={{ margin: "4px", minWidth: "250px" }}>*/}
      {/*        <InternalLink url={InternalLinkTypes.PhotosGallery}>*/}
      {/*          Browse Photos Gallery*/}
      {/*        </InternalLink>*/}
      {/*      </ButtonPrimary>*/}
      {/*    </div>*/}
      {/*  </ContainerContent>*/}
      {/*</section>*/}
      <section>
        <ContainerCta
          title={"Your future happiness is waiting with a smile"}
          subTitle={"Start building your custom home with Grandeur Homes"}
          secondaryLink={InternalLinkTypes.GetStarted}
          secondaryText={"Get Started"}
        />
      </section>
      <section>
        <ContainerContent
          style={{ textAlign: "center", backgroundColor: Colors.LightGray }}
        >
          <TitleSection>Client Reviews</TitleSection>
          <h4 style={{ textTransform: "uppercase" }}>
            Hear from our clients who trusted us to build their dream homes
          </h4>
          <DisplayReviewList reviews={reviews} />
          <br />
          <ButtonPrimary>
            <InternalLink url={InternalLinkTypes.Reviews}>
              Read More Client Reviews
            </InternalLink>
          </ButtonPrimary>
        </ContainerContent>
      </section>
      <section>
        <ContainerCta
          title={"Want to speak to someone?"}
          //subTitle={"Leave your details and our team will reach out to you"}
          secondaryLink={InternalLinkTypes.RequestCallback}
          secondaryText={"Request Callback"}
        />
      </section>
    </Layout>
  );
};

export default IndexPage;
